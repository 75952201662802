import React, { useState, useEffect, useRef } from 'react';
import PriceAndMintGraph from './PriceAndMintGraph';
import Leaderboard from './Leaderboard';
import "./SnailFactsPage.css";

function SnailFactsPage({currentAccount, currentDay, fetchCurrentDay}) {

    return (
        <div>
            <div >
                <PriceAndMintGraph currentDay={currentDay} currentAccount={currentAccount} fetchCurrentDay={fetchCurrentDay} />
            </div>
            <div >
                <Leaderboard currentDay={currentDay} currentAccount={currentAccount} fetchCurrentDay={fetchCurrentDay}/>
            </div>
        </div>
    );
  }
  

export default SnailFactsPage;
