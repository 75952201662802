import React from 'react';
import { FaCheckCircle } from 'react-icons/fa';

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    const { day, price, nfts_minted, userMinted } = payload[0].payload;
    
    return (
      <div className="custom-tooltip" style={{ backgroundColor: '#1a1a1a', color: '#fff', padding: '10px', borderRadius: '5px' }}>
        <p className="label">{`Day: ${day}`}</p>
        <p>{`Price: $${price.toFixed(2)}`}</p>
        <p>{`Snails Minted: ${nfts_minted}`}</p>
        {userMinted ? (
          <p style={{ display: 'flex', alignItems: 'center' }}>
            <FaCheckCircle style={{ color: '#96e89a', marginRight: '5px' }} /> You Minted
          </p>
        ) : null}
      </div>
    );
  }

  return null;
};

export default CustomTooltip;
