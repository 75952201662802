import React, { useState } from 'react';

function TopBar({ togglePage, isHomePage, connectWalletClicked, connectWalletButtonString, getFraxscanLink }) {

  return (
    <div className="top-bar">
        <div className="fraxscan-logo-container">
        <a href={getFraxscanLink()} target="_blank" rel="noopener noreferrer">
            <img src="/fraxscan-logo.png" alt="Fraxscan Logo" className="fraxscan-logo" />
        </a>
        </div>
        <div className="title-container">
        <a href='/'><div className="title">Snailminting</div></a>
        </div>
        <div className="toggle-button-container">
            <button onClick={togglePage} className="toggle-button">
                {isHomePage ? "Snail Facts" : "Minting Page"}
            </button>
        </div>
        <div className="wallet-button-container">
            <button onClick={connectWalletClicked} className="wallet-button">
                {connectWalletButtonString()}
            </button>
        </div>
  </div>
  );
}

export default TopBar;

/*
        <div className="toggle-button-container">
        <button onClick={togglePage} className="toggle-button">
            {isHomePage ? "Snail Facts" : "Minting Page"}
        </button>
        </div>
        <div className="wallet-button-container">
        <button onClick={connectWalletClicked} className="wallet-button">
            {connectWalletButtonString()}
        </button>
        </div>
*/