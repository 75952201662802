// App.js
import React, { useState, useEffect, useRef } from 'react';
import { ethers } from 'ethers';
import { svgData } from './svgData';
import { contractAddress, contractABI, networkInfo } from './config';
import './App.css';
import MintingPage from "./MintingPage";
import SnailFactsPage from "./SnailFactsPage";
import { BrowserRouter as Router, Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import TopBar from './TopBar';

function App() {
  const [isMintingPage, setIsMintingPage] = useState(true);
  const [currentAccount, setCurrentAccount] = useState(null);
  const [network, setNetwork] = useState(null);
  const [correctNetwork, setCorrectNetwork] = useState(false);
  const [contract, setContract] = useState(null);
  const [currentDay, setCurrentDay] = useState('');
  const [nfts, setNfts] = useState([]);
  const [latestNFT, setLatestNFT] = useState(null);
  const [isAnimating, setIsAnimating] = useState(true);
  const [listIsLoading, setListIsLoading] = useState(false);
  const [currentStreak, setCurrentStreak] = useState(0);
  const [message, setMessage] = useState('');
  const [isMinting, setIsMinting] = useState(false);
  const [recipient, setRecipient] = useState('');
  const [retroactiveDay, setRetroactiveDay] = useState('');
  const navigate = useNavigate();
  const location = useLocation();

  // Determine the current path
  const isHomePage = location.pathname === '/';
  const isSnailFacts = location.pathname === '/snailfacts';
  if(!isHomePage && !isSnailFacts) {
    navigate('/');
  }

  const requestAccount = async function() {
    await window.ethereum.request({ method: 'eth_requestAccounts' });
  }

  // Function to connect to the wallet
  const connectWallet = async () => {
    if (typeof window.ethereum !== 'undefined') {
      try {
        const provider = new ethers.BrowserProvider(window.ethereum);
        const signer = await provider.getSigner();
        const address = await signer.getAddress();
        setCurrentAccount(address)

        const newNetwork = await provider.getNetwork();
        if(newNetwork != network) {
          setNetwork(newNetwork);
        }
        const isCorrectNetwork = newNetwork.chainId == networkInfo.chainId;
        if(isCorrectNetwork != correctNetwork) {
          setCorrectNetwork(isCorrectNetwork);
        }

        const newContract = new ethers.Contract(contractAddress, contractABI, signer);
        if(newContract != contract) {
          setContract(newContract);
        }
  
      } catch (error) {
        console.error('Error connecting to wallet:', error);
      }
    } else {
      alert('MetaMask is not installed. Please install it to use this app.');
    }
  };

  const connectWalletClicked = async () => {
    if(!currentAccount) {
      await connectWallet();
    }
  }

  const togglePage = async () => {
    if(isHomePage) {
      navigate('/snailfacts');
    } else {
      navigate('/')
    }
  }

  const connectWalletButtonString = () => {
    if(!currentAccount) {
      return "Connect Wallet";
    } else if(!correctNetwork) { 
      return "Wrong Network";
    } else {
      return currentAccount.substr(0, 10);
    }
  }

  const getFraxscanLink = () => {
    return `${networkInfo.explorer}/address/${contractAddress}`;
  };

  async function fetchCurrentDay() {
    console.log("Going to try to set current day");
    try {
        // Using a local Ethereum provider (Hardhat network)
        const provider = new ethers.JsonRpcProvider(networkInfo.networkRPC);
    
        // Replace with your contract's ABI and address
        const contract = new ethers.Contract(contractAddress, contractABI, provider);
    
        // Fetch the current day from the contract
        const day = await contract.currentDay();
        console.log("got current day: " + day);
        setCurrentDay(day.toString());
    } catch (error) {
        console.error("Error fetching current day:", error);
    }
  }

  function createNFTfromMetadata(tokenId, metadata) {
    const attributes = metadata.attributes.reduce((acc, attribute) => {
        acc[attribute.trait_type.toLowerCase()] = attribute.value;
        return acc;
    }, {});

    return {
        tokenId: tokenId,
        day: attributes.day,
        name: metadata.name,
        description: metadata.description,
        image: metadata.image,
        telegramSnail: attributes.telegram_snail,
        streak: attributes.streak
    }
  }

  function animationFinished () {
    setLatestNFT(null);
    setIsAnimating(false);
  }
    
  async function fetchNewNFT() {
    if (typeof window.ethereum !== 'undefined') {
        await requestAccount();
        const provider = new ethers.BrowserProvider(window.ethereum);
        const signer = await provider.getSigner();
        const contract = new ethers.Contract(contractAddress, contractABI, signer);
  
        const address = await signer.getAddress();

        const newNFTId = parseInt(await contract.tokenOfOwnerByDay(address, currentDay));
        const tokenURI = await contract.tokenURI(newNFTId.toString());
        const metadata = JSON.parse(tokenURI);
    
        const newArray = JSON.parse(JSON.stringify(nfts));
        newArray.unshift(createNFTfromMetadata(newNFTId, metadata));
        newArray.sort((a, b) => b.day - a.day);
        setLatestNFT(newNFTId);
        setIsAnimating(true);
        setNfts(newArray);
    }
  }

  async function fetchNFTs() {
      if (typeof window.ethereum !== 'undefined') {
          await requestAccount();
          const provider = new ethers.BrowserProvider(window.ethereum);
          const signer = await provider.getSigner();
          const contract = new ethers.Contract(contractAddress, contractABI, signer);
    
          const address = await signer.getAddress();

          try {
              const balance = parseInt(await contract.balanceOf(address));
              setListIsLoading(true); // Start loading
      
              // Initialize an array to hold all fetched NFTs
              let allNFTs = [];
      
              // Fetch NFTs in reverse order, in batches of 10
              for (let startIndex = balance -1; startIndex >= 0; startIndex -= 10) {
      
                  const endIndex = Math.max(0, startIndex - 9); // Ensure we don't go below 0
                  const tokenData = await contract.getTokensOfOwnerByRange(address, endIndex, startIndex + 1);
                  let fetchedNFTs = [];
      
                  for(var i=0; i<tokenData.length; i++) {
                      const thisTokenData = tokenData[i];
                      const tokenId = thisTokenData.tokenId.toString();
                      const tokenURI = thisTokenData.tokenURI;
                      const metadata = JSON.parse(tokenURI);
                      fetchedNFTs.push(createNFTfromMetadata(tokenId, metadata));
                  }
      
      
                  // Add the fetched NFTs to the main array
                  allNFTs = [...allNFTs, ...fetchedNFTs];
      
                  // Sort fetched NFTs by day
                  allNFTs.sort((a, b) => b.day - a.day);
                  
                  // Make sure we're creating a fresh array to trigger a reload
                  const newNFTs = JSON.parse(JSON.stringify(allNFTs));

                  // Update the state after each batch to display the NFTs
                  setNfts(newNFTs);
              }
      
          } catch (error) {
              console.error('Error fetching NFTs:', error);
          } finally {
              setListIsLoading(false); // Loading complete
          }
      }
  }

  const setupDB = async () => {
    const code = prompt('Enter db reset code:');
    try {
      const data = { code: code };
      const endpoint = networkInfo.backendEndpoint + '/reset_db';
      const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json' // Set the content type to JSON
        },
        body: JSON.stringify(data) // Convert the data to JSON format
      });
    } catch(error) {
      console.log("error resetting db: ");
      console.log(error);
    }
  }

  async function mintNFT() {
      if(!correctNetwork) {
          return;
      }
      if (typeof window.ethereum !== 'undefined') {
          await requestAccount();
          const provider = new ethers.BrowserProvider(window.ethereum);
          const signer = await provider.getSigner();
          const contract = new ethers.Contract(contractAddress, contractABI, signer);
          try {
              setIsMinting(true);
              const tx = await contract.mint();
              const receipt = await tx.wait();
              setMessage('Snail minted successfully!');
              // Trigger backend to check for new mints
              await fetchNewNFT();
              await fetchCurrentStreak()
              await fetch(networkInfo.backendEndpoint + '/trigger-update', {
                  method: 'POST',
              });
          } catch (error) {
              console.error('Error minting snail: ', error);
              setMessage('Error minting snail: ' + error.reason);
          } finally {
              setIsMinting(false);
          }
      }
  }
  async function fetchCurrentStreak() {
    try {
        await requestAccount()
        // Using a local Ethereum provider (Hardhat network)
        const provider = new ethers.JsonRpcProvider(networkInfo.networkRPC);

        // Replace with your contract's ABI and address
        const contract = new ethers.Contract(contractAddress, contractABI, provider);
        // Fetch the current day from the contract
        const streak = await contract.currentStreak(currentAccount);
        setCurrentStreak(parseInt(streak));
    } catch (error) {
        console.error("Error fetching current streak:", error);
    }
  }

  async function startNewDay() {
    if (typeof window.ethereum !== 'undefined') {

        const price = prompt('Enter today\'s price:');
    
        try {
            const tx = await contract.startNewDay(price, false);
            await tx.wait();
            setMessage('Day rolled over successfully!');
            const response = await fetch(networkInfo.backendEndpoint + '/trigger-day-update', {
                method: 'POST',
            });
            if (response.ok) {
                console.log('Day update triggered successfully');
            } else {
                console.error('Failed to trigger day update');
            }
            fetchCurrentDay();
        } catch (error) {
            console.error('Error rolling over day:', error);
            setMessage('Error rolling over day: ' + error.reason);
        }
    }
  }

  async function finalDayHit() {
    if (typeof window.ethereum !== 'undefined') {

        const price = prompt('Enter today\'s price (should be above price target FINAL DAY):');
    
        try {
            const tx = await contract.startNewDay(price, true);
            await tx.wait();
            setMessage('Set final day successfully!');
            fetchCurrentDay();
        } catch (error) {
            console.error('Error starting final day:', error);
            setMessage('Error starting final day: ' + error.reason);
        }
    }
  }

  async function endMinting() {
    if (typeof window.ethereum !== 'undefined') {

        try {
            const tx = await contract.snailMintingOver();
            await tx.wait();
            setMessage('Snail minting ended successfully!');
        } catch (error) {
            console.error('Error ending minting:', error);
            setMessage('Error ending minting: ' + error.reason);
        }
    }
  }

  async function startMinting() {
    if (typeof window.ethereum !== 'undefined') {

        try {
            const tx = await contract.startMinting();
            await tx.wait();
            setMessage('Minting started successfully!');
        } catch (error) {
            console.error('Error strting minting:', error);
            setMessage('Error starting minting.');
        }
    }
  }

  async function retroactiveMint() {
    if (typeof window.ethereum !== 'undefined') {

        try {
            const tx = await contract.retroactiveMint(recipient, retroactiveDay);
            await tx.wait();
            setMessage(`Retroactive NFT minted for ${recipient} on day ${retroactiveDay}`);
            if(recipient == networkInfo.deployerAccount) {
                fetchNFTs()
            }
        } catch (error) {
            console.error('Error retroactively minting NFT:', error);
            setMessage('Error retroactively minting NFT.');
        }
    }
  }

  async function correctStreaks() {
    if (typeof window.ethereum !== 'undefined') {
        const fixAddress = prompt("Address to fix for: ");
        const startFixDay = parseInt(prompt('Enter day to start fix:'));
        const endFixDay = parseInt(prompt("End fix day: "));
    
        try {
            const tx = await contract.correctStreaks(fixAddress, startFixDay, endFixDay);
            await tx.wait();
            setMessage(`Fixed streaks for NFT minted for ${recipient} on day ${retroactiveDay}`);
            fetchNFTs();
        } catch (error) {
            console.error('Error retroactively minting NFT:', error);
            setMessage('Error retroactively minting NFT.');
        }
    }
  }

  useEffect(() => {
      if(correctNetwork) {
        setMessage("");
        fetchNFTs();
        fetchCurrentStreak();
        fetchCurrentDay()
    } else {
        setMessage("Please connect to " + networkInfo.name);
        setNfts([]);
    }

    if (typeof window.ethereum !== 'undefined') {
      window.ethereum.request({ method: 'eth_accounts' }).then((accounts) => {
        if (accounts.length > 0) {
          // Automatically connect if there are already connected accounts
          connectWallet();
        }
      });
      // Listen for account changes
      if (window.ethereum) {
        window.ethereum.on('accountsChanged', () => {
          window.location.reload();  // Reload the page on account change
        });
        // Handle network changes
        window.ethereum.on('chainChanged', (chainId) => {
          // window.location.reload(); // Reload the page to reset the app state
          setCorrectNetwork(chainId == networkInfo.chainId);
        });
      }
    }

    return () => {
      // Cleanup the event listener on component unmount
      if (window.ethereum) {
        window.ethereum.removeListener('chainChanged', () => {});
        window.ethereum.removeListener('accountsChanged', () => {});
      }
    };
  }, [correctNetwork]);

  return (
    <div className="App">
      <TopBar 
        togglePage={togglePage}
        isHomePage={isHomePage}
        connectWalletClicked={connectWalletClicked}
        connectWalletButtonString={connectWalletButtonString}
        getFraxscanLink={getFraxscanLink}
      />
      <Routes>
        <Route
          path="/"
          element={(<MintingPage 
            correctNetwork={correctNetwork} 
            currentAccount={currentAccount} 
            contract={contract} 
            requestAccount={requestAccount} 
            currentDay={currentDay}
            fetchCurrentDay={fetchCurrentDay}
            fetchNFTs={fetchNFTs}
            fetchNewNFT={fetchNewNFT}
            nfts={nfts}
            animationFinished={animationFinished}
            latestNFT={latestNFT}
            isAnimating={isAnimating}
            listIsLoading={listIsLoading}
            currentStreak={currentStreak}
            message={message}
            mintNFT={mintNFT}
            isMinting={isMinting}
            startNewDay={startNewDay}
            startMinting={startMinting}
            finalDayHit={finalDayHit}
            endMinting={endMinting}
            correctStreaks={correctStreaks}
            retroactiveMint={retroactiveMint}
            recipient={recipient}
            retroactiveDay={retroactiveDay}
            setRecipient={setRecipient}
            setRetroactiveDay={setRetroactiveDay}
            setupDB={setupDB}
            />)}
        />
        <Route
          path="/snailfacts"
          element={(<SnailFactsPage 
            currentAccount={currentAccount}
            currentDay={currentDay}
            fetchCurrentDay={fetchCurrentDay}
          />)}
        />
      </Routes>
      <div className="background-image">
          <img src={svgData} />
      </div>
    </div>
  );
}

function AppWithRouter() {
  return (
    <Router>
      <App />
    </Router>
  );
}

export default AppWithRouter;