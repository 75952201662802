import React, { useState, useEffect } from 'react';
import './Leaderboard.css';
import { FaCopy } from 'react-icons/fa';
import { networkInfo } from './config';

function Leaderboard({currentDay, currentAccount, fetchCurrentDay}) {
  const [sortConfig, setSortConfig] = useState({ key: 'nfts_minted', direction: 'descending' });
  const [currentPage, setCurrentPage] = useState(1);
  const [resultsPerPage, setResultsPerPage] = useState(10);
  const [data, setData] = useState([])
  const [copiedAddress, setCopiedAddress] = useState(null);
  const [userRank, setUserRank] = useState(-1);

  useEffect(function(){
    if(!currentDay) {
      fetchCurrentDay();
    }
    
    const fetchGraphData = async() => {
      try {
        var endpoint = networkInfo.backendEndpoint + '/leaderboard';
        const response = await fetch(endpoint);
        const serverData = await response.json();
        const parsedData = [];
        serverData.sort(function(a, b){return b.nfts_minted - a.nfts_minted});
        var rank = -1;
        for(var i=0; i<serverData.length; i++) {
          var thisData = serverData[i];
          thisData["current_streak"] = calculateCurrentStreak(thisData.last_day_minted, thisData.last_streak);
          const thisUsername = thisData["username"];
          var isUser = false;
          if(currentAccount) {
            isUser = thisUsername.toLowerCase() == currentAccount.toLowerCase();
          }
          thisData["isUser"] = isUser;
          if(isUser) {
            rank = i + 1;
            setUserRank(rank);
          }
          parsedData.push(thisData);
        }
        setData(parsedData);
      }  catch (error) {
        console.error("Error fetching graph data:", error);
      }
    }

    fetchGraphData();
  },[currentAccount, currentDay]);

  const sortedData = [...data].sort((a, b) => {
    if (sortConfig.direction === 'ascending') {
      return a[sortConfig.key] > b[sortConfig.key] ? 1 : -1;
    } else if (sortConfig.direction === 'descending') {
      return a[sortConfig.key] < b[sortConfig.key] ? 1 : -1;
    }
    return 0; // No sorting
  });

  const totalPages = Math.ceil(sortedData.length / resultsPerPage);

  const handleSort = (key) => {
    let direction = 'descending';
    if (sortConfig.key === key && sortConfig.direction === 'descending') {
      direction = 'ascending';
    }
    setSortConfig({ key, direction });
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleResultsPerPageChange = (event) => {
    setResultsPerPage(parseInt(event.target.value));
    setCurrentPage(1); // Reset to first page
  };

  const displayedData = sortedData.slice(
    (currentPage - 1) * resultsPerPage,
    currentPage * resultsPerPage
  );

  function truncateAddress(address) {
    return `${address.slice(0, 6)}...${address.slice(-4)}`;
  }

  const handleCopy = (address) => {
    navigator.clipboard.writeText(address);
    setCopiedAddress(address);
    setTimeout(() => setCopiedAddress(null), 1000); // Reset after 1 seconds
  };

  function calculateCurrentStreak(lastDayMinted, lastStreak) {
    if (currentDay - lastDayMinted > 1) {
      return 0;
    }
    return lastStreak;
  }
  const getLeaderboardString = () => {
    var base = "Leaderboard";
    return base;
    if(!currentAccount) {
      return base;
    }

    base += " - Your Rank: "

    if(userRank == -1) {
      base += "No Rank"
    } else {
      base += userRank;
    }

    return base;
  }

  const getUserRankString = () => {
    if(userRank == -1) {
      return "No Rank";
    }
    /*
      <div className='leaderboard-top'>
        <h2 className='leaderboard-title'>{getLeaderboardString()}</h2>
        {currentAccount && (
        <h2 className='user-rank'>Your Rank: {getUserRankString()}</h2>
        )}
      </div>
    */
    return userRank;
  }

  return (
    <div className="leaderboard-container">
      <div className='leaderboard-top'>
        <h2 className='leaderboard-title'>{getLeaderboardString()}</h2>
        {currentAccount && (
        <h2 className='user-rank'>Your Rank: {getUserRankString()}</h2>
        )}
      </div>

      <div className="pagination-controls">
        <label htmlFor="results-per-page">Results per page:</label>
        <select
          id="results-per-page"
          value={resultsPerPage}
          onChange={handleResultsPerPageChange}
        >
          <option value="10">10</option>
          <option value="25">25</option>
          <option value="50">50</option>
          <option value={sortedData.length}>All</option>
        </select>
      </div>
      <table className="leaderboard-table">
        <thead>
          <tr>
            <th>#</th>
            <th onClick={() => handleSort('username')} className={sortConfig.key === 'username' ? sortConfig.direction : ''}>Username</th>
            <th onClick={() => handleSort('nfts_minted')} className={sortConfig.key === 'nfts_minted' ? sortConfig.direction : ''}>NFTs Minted</th>
            <th onClick={() => handleSort('nfts_held')} className={sortConfig.key === 'nfts_held' ? sortConfig.direction : ''}>NFTs Held</th>
            <th onClick={() => handleSort('best_streak')} className={sortConfig.key === 'best_streak' ? sortConfig.direction : ''}>Best Minting Streak</th>
            <th onClick={() => handleSort('current_streak')} className={sortConfig.key === 'current_streak' ? sortConfig.direction : ''}>Current Minting Streak</th>
          </tr>
        </thead>
        <tbody>
          {displayedData.map((user, index) => {
            const truncatedAddress = truncateAddress(user.username);
            return (
              <tr key={index} className={`leaderboard-row ${user.isUser ? 'current-user-row' : ''}`}>
                <td data-label="#"> {(currentPage - 1) * resultsPerPage + index + 1} </td>
                <td data-label="Username"> {truncatedAddress} <FaCopy
                  style={{ cursor: 'pointer', marginLeft: '8px' }}
                  onClick={() => handleCopy(user.username)}
                  className={copiedAddress === user.username ? 'icon-copied' : ''}
                /></td>
                <td data-label="NFTs Minted"> {user.nfts_minted} </td>
                <td data-label="NFTs Held"> {user.nfts_held} </td>
                <td data-label="Best Minting Streak"> {user.best_streak} </td>
                <td data-label="Current Minting Streak"> {user.current_streak} </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="pagination-navigation">
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          style={{ visibility: currentPage > 1 ? 'visible' : 'hidden' }}
        >
          Previous
        </button>
        <span className="page-label">Page {currentPage} of {totalPages}</span>
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          style={{ visibility: currentPage < totalPages ? 'visible' : 'hidden' }}
        >
          Next
        </button>
      </div>
    </div>
  );
}

export default Leaderboard;
