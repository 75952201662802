import React, { useState, useEffect, useRef } from 'react';
import "./PriceAndMintGraph.css";
import CustomTooltip from './CustomTooltip';
import { networkInfo } from './config';
import { ClipLoader } from 'react-spinners'; // Import a spinner
import { FaCheckCircle, FaArrowUp, FaArrowDown, FaChartBar, FaChartLine } from 'react-icons/fa';
import {
  LineChart,
  Line,
  BarChart,
  Cell,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ComposedChart,
  ResponsiveContainer,
} from 'recharts';

const CustomDot = (props) => {
    const { cx, cy, payload } = props;
    const userMinted = payload.userMinted;
    const baseSize = 34;
    const iconSize = 12;
    const snailSize = userMinted ? (baseSize * 1.0) : baseSize;
    const newOption = true
    const imageAddress2 = payload.userMinted ? "/snail_star_5.png" : "/fxs_snail_2.png"
    const imageAddress = "/fxs_snail_2.png";

    if (newOption) {
      return (
        <g>
          {userMinted && (
            <FaCheckCircle 
              x={cx - iconSize/2}
              y={cy - snailSize + 6}
              style={{
                color: '#96e89a',
                fontSize: `${iconSize}px`
              }}
            />
          )}
          <image
            href={imageAddress} // Path to your NFT image
            x={cx - snailSize/2} // Adjust the x position
            y={cy - snailSize/2} // Adjust the y position
            width={snailSize}
            height={snailSize}
          />
        </g>
      );
    } else {
      return (
        <image
          href={imageAddress2} // Path to your NFT image
          x={cx - snailSize/2} // Adjust the x position
          y={cy - snailSize/2} // Adjust the y position
          width={snailSize}
          height={snailSize}
      />
      )
    }
};

const DataRange = {
  week: "week",
  onchain: "onchain",
  month: "month",
  allTime: "alltime"
};

function PriceAndMintGraph({ currentDay, currentAccount, fetchCurrentDay }) {
    const [selectedRange, setSelectedRange] = useState('all');
    const [graphData, setGraphData] = useState([]);
    const [dataFetched, setDataFetched] = useState(false);
    const [rawData, setRawData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [fetchedWithUserData, setFetchedWithUserData] = useState(false);
    const defaultDataRange = DataRange.month;
    useEffect(function(){
      fetchCurrentDay();
      const fetchGraphData = async() => {
        setLoading(true);
        try {
          var endpoint = networkInfo.backendEndpoint + '/minting-day-nft-data';
          if(currentAccount) {
            endpoint += "?userAddress=" + currentAccount;
            setFetchedWithUserData(true);
          }
          const response = await fetch(endpoint);
          const serverData = await response.json();
          const parsedData = serverData.map(dayData => {
            return {
                ...dayData,
                price: parseFloat(dayData.price.replace('$', '')) // Convert the price string to a float
            };
          });
          setRawData(parsedData)
          const filteredData = filterDataByRange(defaultDataRange, parsedData);
          setGraphData(filteredData);
        }  catch (error) {
          console.error("Error fetching graph data:", error);
        } finally {
          setLoading(false);
        }
      }
      if(!dataFetched || (!fetchedWithUserData && currentAccount)) {
        fetchGraphData();
        setDataFetched(true);
      } else {
        const filteredData = filterDataByRange(selectedRange, rawData);
        const newData = [...filteredData];
        setGraphData(newData);
      }
      

    },[selectedRange, currentDay, currentAccount]);

    const filterDataByRange = (range, data) => {
      let filteredData;
  
      if (range === DataRange.week) {
        filteredData = data.filter((item) => item.day >= currentDay - 6); // Last 7 days including today
      } else if(range === DataRange.onchain) {
        filteredData = data.filter((item) => item.day >= 51); // Last 7 days including today
      } else if (range === DataRange.month) {
        filteredData = data.filter((item) => item.day >= currentDay - 29); // Last 30 days including today
      } else {
        filteredData = data; // 'all' range, return all data
      }

      return filteredData;
    };
  
    const handleRangeChange = (range) => {
      setSelectedRange(range);
    };

    function getBarColor(entry, index) {
      return entry.day <= 50 ? '#555599' : '#8884d8';
    }
  
    function getButtonStringForRange(range) {
      if(range == DataRange.week) {
        return "Last Week";
      } else if(range == DataRange.month) {
        return "Last Month";
      } else if(range == DataRange.onchain) {
        return "Onchain Era"
      } else if(range == DataRange.allTime) {
        return "All Time"
      }
    }
  
    const legendPayload = [
      { value: "Telegram Mints", type: "square", color: "#555599" },
      { value: "Onchain Era Mints", type: "square", color: "#8884d8" },
      { value: "Price", type: "line", color: "#fff" }
    ];
  
    const computeRegionStats = (data) => {
      if(!data || data.length == 0) {
        return {
          highestPrice: 0,
          highestPriceDay: 0,
          lowestPrice: 0,
          lowestPriceDay: 0,
          mostNFTs: 0,
          mostNFTsDay: 0,
          leastNFTs: 0,
          leastNFTsDay:0
        }
      }
      const highestPrice = Math.max(...data.map(d => d.price));
      const lowestPrice = Math.min(...data.map(d => d.price));
      const mostNFTs = Math.max(...data.map(d => d.nfts_minted));
      const leastNFTs = Math.min(...data.map(d => d.nfts_minted));
    
      const highestPriceDay = data.find(d => d.price === highestPrice).day;
      const lowestPriceDay = data.find(d => d.price === lowestPrice).day;
      const mostNFTsDay = data.find(d => d.nfts_minted === mostNFTs).day;
      const leastNFTsDay = data.find(d => d.nfts_minted === leastNFTs).day;
    
      return {
        highestPrice,
        highestPriceDay,
        lowestPrice,
        lowestPriceDay,
        mostNFTs,
        mostNFTsDay,
        leastNFTs,
        leastNFTsDay
      };
    };

    const computedStats = computeRegionStats(graphData);

    const ChartHeader = ({ stats }) => {
      return (
        <div className="snail-stats-container">
          <h2>Snail Stats</h2>
          <ul className="snail-stats-list">
            <li>Highest Price: Day {stats.highestPriceDay} (${stats.highestPrice.toFixed(2)})</li>
            <li>Lowest Price: Day {stats.lowestPriceDay} (${stats.lowestPrice.toFixed(2)})</li>
            <li>Most NFTs Minted: Day {stats.mostNFTsDay} ({stats.mostNFTs} NFTs)</li>
            <li>Least NFTs Minted: Day {stats.leastMintedDay} ({stats.leastNFTs} NFTs)</li>
          </ul>
        </div>
      );
    }
    
    return (
    <div>
        <h2 className='snailmints-title'>Snailmints</h2>
        <ResponsiveContainer width="100%" height={450}>
        {loading && (
        <div style={{
          position: 'absolute',
          display: 'flex',
          float: 'left',
          top:0,
          right:0,
          width: '100%',
          height: '700px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'clear',
          color:'red',
          zIndex: 1,
        }}>
          <ClipLoader size={50} color={"#123abc"} loading={loading} />
        </div>
      )}
        <ComposedChart
            data={graphData}
            margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
            }}
        >
            <CartesianGrid strokeDasharray="3 3" stroke="#444" />
            <XAxis dataKey="day" stroke="#ddd" />
            <YAxis 
                yAxisId="left" 
                orientation="left" 
                stroke="#ddd"
                domain={['auto', 'dataMax * 1.01']}
                tickFormatter={(value) => `$${value.toFixed(2)}`}
            />
            <YAxis yAxisId="right" orientation="right" stroke="#ddd" />
            <Tooltip content={<CustomTooltip />} />
            <Legend 
              layout="horizontal" 
              verticalAlign="bottom" 
              align="center"
              payload={legendPayload}/>
            <Bar 
                yAxisId="right" 
                dataKey="nfts_minted" 
                fill={"#888"} // Slightly darker for days 1-50         
                animationDuration={500}  // Faster transition
                animationEasing="ease-out"  // Smoother easing
                >
                {graphData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={getBarColor(entry, index)} />
                ))}
            </Bar>
            <Line
              yAxisId="left"
              type="monotone"
              dataKey="price"
              stroke="#fff"
              strokeWidth={4}
              dot={<CustomDot />}
              animationDuration={500}  // Faster transition
              animationEasing="ease-out"  // Smoother easing
            />
        </ComposedChart>
        </ResponsiveContainer>
        <div className="range-selector">
            <button onClick={() => handleRangeChange(DataRange.allTime)}>All Time</button>
            <button onClick={() => handleRangeChange(DataRange.month)}>Last 30 Days</button>
            <button onClick={() => handleRangeChange(DataRange.week)}>Last Week</button>
            <button onClick={() => handleRangeChange(DataRange.onchain)}>Onchain Era</button>
        </div>
        
    </div>

    );
  }

export default PriceAndMintGraph;
