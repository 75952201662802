import Snailminting from './contracts/Snailminting.json'
const Networks = {
    fraxtalTestnet: "Fraxtal",
    localhost: "localhost",
    fraxtal: "Fraxtal"
};

const fraxtalInfo = {
    name: Networks.fraxtal,
    deployerAccount: "0x3689c216f8f6ce7e2CE2a27c81a23096A787F532",
    networkRPC: "https://rpc.frax.com",
    explorer: "https://fraxscan.com",
    chainId: 252,
    backendEndpoint: "https://snailposting-backend.onrender.com"
}

const networkInfo = fraxtalInfo;
const contractAddress = Snailminting.address;
const contractABI = Snailminting.abi;
export { contractAddress, contractABI, networkInfo };