import React, { useState, useEffect, useRef } from 'react';
import { ethers } from 'ethers';
import { svgData } from './svgData';
import "./MintingPage.css";

import { contractAddress, contractABI, networkInfo } from './config';

function MintingPage(
                    {correctNetwork, 
                    currentAccount, 
                    contract, 
                    requestAccount, 
                    currentDay, 
                    fetchCurrentDay, 
                    fetchNFTs, 
                    fetchNewNFT, 
                    latestNFT,
                    nfts,
                    isAnimating, 
                    listIsLoading,
                    animationFinished,
                    currentStreak,
                    message,
                    mintNFT,
                    isMinting,
                    startNewDay,
                    startMinting,
                    finalDayHit,
                    endMinting,
                    correctStreaks,
                    retroactiveMint,
                    recipient,
                    retroactiveDay,
                    setRecipient,
                    setRetroactiveDay,
                    setupDB
                }) {

    const [positions, setPositions] = useState({});
    const preloadedGifSrc = useRef(null);  // Ref to store the preloaded image src

    useEffect(function(){
        console.log("Minting Page rerender")
        fetchCurrentDay();
        const img = new Image();
        img.src = "./output_2_bg_fast_sm.gif";
        img.onload = () => {
            preloadedGifSrc.current = img.src;  // Store the src of the fully loaded img
        };

    }, [correctNetwork, contract, currentAccount, nfts, isAnimating, latestNFT, listIsLoading]);

    function isDeployerAccount() {
        return currentAccount == networkInfo.deployerAccount;
    }

    function getSnailListText() {
        if(listIsLoading) {
            return "Loading your snails...";
        } else {
            return "You don't own any snails yet.";
        }
    }


    const isMintingDisabled = () => {
        return isMinting;
    }
    
    const mintingButtonText = () => {
        return isMinting ? "Processing..." : "Mint Snail";
    }
    
    const getDayOrDaysText = function() {
        const text = currentStreak == 1 ? "day" : "days";
        return text;
    }

    // Function to generate Etherscan URL
    function getTokenAddressLink(tokenId) {
        let url = `${networkInfo.explorer}/token/${contractAddress}?a=${tokenId}`;; // Default for local testing
        return url;
    }

    return (
        <div className='minting-page-wrapper'>
            <h1 className='mintingTitle'>Day {currentDay} of minting snails every day until FXS is at $100</h1>
            <button disabled={isMintingDisabled()} onClick={mintNFT}>{mintingButtonText()}</button>
            {
            isDeployerAccount() && 
            <button onClick={startNewDay}>Start New Day</button>
            }
            {
            isDeployerAccount() &&
            <button onClick={startMinting}>Start Minting</button>
            }
            {
            isDeployerAccount() &&
            <button onClick={finalDayHit}>Final Day Hit</button>
            }
            {
            isDeployerAccount() &&
            <button onClick={endMinting}>End Minting</button>
            }
            {
            isDeployerAccount() &&
            <button onClick={correctStreaks}>Fix Streaks</button>
            }
            {
            isDeployerAccount() &&
            <button onClick={setupDB}>Setup DB</button>
            }
            <p>{message}</p>
            {isDeployerAccount() && 
                <div>
            <h2>Retroactive Minting</h2>
                <form onSubmit={(e) => {
                e.preventDefault();
                retroactiveMint();
                }}>
                <input
                    type="text"
                    value={recipient}
                    onChange={(e) => setRecipient(e.target.value)}
                    placeholder="Recipient Address"
                    required
                />
                <input
                    type="number"
                    value={retroactiveDay}
                    onChange={(e) => setRetroactiveDay(e.target.value)}
                    placeholder="Day"
                    required
                />
                <button type="submit">Retroactive Mint</button>
                </form>
                </div>
            }

            <h2>Your Snails</h2>
            <h3>You have been minting snails everyday for the last {currentStreak} {getDayOrDaysText()}</h3>
            <div className="nft-list">
                {nfts.length > 0 ? (
                nfts.map((nft) => (
                    <a href={getTokenAddressLink(nft.tokenId)} target="_blank" rel="noopener noreferrer" key={nft.tokenId}>
                    <div
                        key={nft.tokenId}
                        className={`nft-item ${nft.tokenId === latestNFT ? 'newly-minted' : ''}`}
                        onAnimationEnd={() => animationFinished()} // Stop animation once it ends
                    >
                    <div className="nft-footer">
                        <p className="nft-tokenId">Token ID: {nft.tokenId}</p>
                        <p className="nft-streak">Streak: {nft.streak}</p>
                    </div>
                    {
                        isAnimating && nft.tokenId == latestNFT && preloadedGifSrc.current ? 
                            <img src={preloadedGifSrc.current} alt={`NFT ${nft.tokenId}`} /> : 
                            <img src={nft.image} alt={`NFT ${nft.tokenId}`} />
                        }
                    {nft.telegramSnail ? <p className="nft-telegram">(Telegram Snail)</p> : null}
                    <div className="nft-text-center">
                        <p className="nft-name">{nft.name}</p>
                        <p className="nft-description">{nft.description}</p>
                    </div>
                    </div>
                    </a>
                ))
                ) : (
                <p>{getSnailListText()}</p>
                )}
            </div>
    </div>
    )
}

export default MintingPage;